import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./LandingBanner.scss"
// import DefaultForm from "../../components/forms/default-form-module"
// import FormFields from "../../../static/forms/offplan_guide.json"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const LandingBanner = ({ pageData, reviewData }) => {
  // const [show, setShow] = useState(false)

  // const handleModal = () => {
  //   setShow(true)
  // }

  // const handleClose = () => {
  //   setShow(false)
  // }

  const handleScroll = () => {
    const getId = document.getElementById("offplan-guide-download-section")
    getId && getId.scrollIntoView({ behavior: "smooth" })
  }
  const bannerData = pageData.banner
  var imagename = "page.banner_section_banner_image.landing_banner_image"

  let processedImages = JSON.stringify({})
  if (pageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    processedImages = pageData?.imagetransforms?.banner_section_banner_image_Transforms
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const guidePdf = pageData?.pdf?.url

  const handleCalculator = () => {
    const getId = document.getElementById("mortage-calc-landing")
    getId && getId.scrollIntoView({ behavior: "smooth", position: "bottom" })
  }
  return (
    <div className={`landing-banner-wrapper ${pageData?.custom_css_classname}`}>
      <ImageModule
        ImageSrc={bannerData?.image}
        altText={`${
          bannerData?.alternativeText
            ? bannerData?.alternativeText
            : bannerData.banner_title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={pageData?.strapi_id}
        classNames="img-fluid banner-img"
      />

      <div className="overlay-bg"></div>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="banner-content-section">
            <div className="banner-content">
              <h1>{bannerData?.banner_title}</h1>
              <p className="description">
                <ContentModule
                  Content={bannerData?.banner_content?.data?.banner_content}
                />
              </p>
              <div className="cta-section">
                {bannerData?.cta_1_title === "Calculate your mortgage" ? (
                  <a
                    onClick={handleCalculator}
                    href="javascript:void(0)"
                    className="button button-filled-green mortgage-cta-banner"
                  >
                    {bannerData.cta_1_title}
                  </a>
                ) : (
                  <CTALink
                    class="button button-filled-green"
                    link={
                      bannerData.cta_1_link
                        ? bannerData.cta_1_link
                        : { external_link: bannerData?.cta_1_custom_link }
                    }
                    // link_type={
                    //   bannerData?.cta_2_custom_link?.includes("http")
                    //     ? "external"
                    //     : "internal"
                    // }
                    title={bannerData.cta_1_title}
                    target_window={
                      bannerData?.cta_1_custom_link?.includes("http")
                        ? "new_window"
                        : bannerData.cta_1_link?.target_window
                    }
                  />
                )}
                {bannerData?.cta_2_title === "download guide" ? (
                  <a
                    onClick={handleScroll}
                    className="button button-outline-white"
                    href="javascript:void(0)"
                  >
                    {bannerData.cta_2_title}
                  </a>
                ) : (
                  <CTALink
                    class="button button-outline-white"
                    link={
                      bannerData.cta_2_link
                        ? bannerData.cta_2_link
                        : { external_link: bannerData?.cta_2_custom_link }
                    }
                    title={bannerData.cta_2_title}
                    // link_type={
                    //   bannerData?.cta_2_custom_link?.includes("http")
                    //     ? "external"
                    //     : "internal"
                    // }
                    target_window={
                      bannerData?.cta_2_custom_link?.includes("http")
                        ? "new_window"
                        : bannerData.cta_2_link?.target_window
                    }
                  />
                )}
              </div>
              {bannerData?.show_review && reviewData && (
                <div className="review-section">
                  <div>
                  <span>
                    <i className="icon g-review" />
                  </span>
                  <span className="rating-star">
                    <i className="icon star-rating" />
                  </span>
                  </div>

                  <span className="vetical-review-line"></span>

                  <div className="feefo-block">
                  <span>
                    <i className="icon feefo-icon"/>
                  </span>
                  <span className="rating-star feefo">
                    <i className="icon star-rating" />
                  </span>
                  </div>
                  {/* <p className="review-description">
                    <span className="star-value">
                      {reviewData?.node?.star_rating}/5
                    </span>
                    <span className="total-review">
                      <ContentModule
                        Content={
                          reviewData?.node?.reviews_count?.data?.reviews_count
                        }
                      />
                    </span>
                  </p> */}
                </div>
              )}
            </div>
          </div>
        </Container>
      </ScrollAnimation>
      {/* <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form team-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper team-form">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={FormFields} 
                formTitle="Offplan Guide"
                sourceUrl={pageurl}
                emailSubUser={`Investment Guide Report 2023 Download`}
                emailSubAdmin={`Investment Guide Download Request`}
                guidePdf={guidePdf}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>  */}
    </div>
  )
}

export default LandingBanner
